import { Alert } from 'components/Alert';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Logger, { LoggingModule } from 'services/Logger';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { ProfileUpdateMessages } from 'store/profile/constants';
import {
  getProfile,
  resetValidationState,
  sendIDWPin,
  sendIDWVerification,
  updatePhone
} from 'store/profile/actions';
import * as profileSelectors from 'store/profile/selectors';
import { Consumer, SendPinProps, ValidateResultsState } from 'store/profile/types';
import { RootState } from 'store/types';
import { UPDATE_DISCLAIMER } from './variables';
import EditPhoneComponent from 'components/EditPhone';
import { getProfileVerifyValues, isConsumerVerified } from 'shared/src/store/profile/util';
import cloneDeep from 'lodash/cloneDeep';

export interface EditPhoneScreenProps extends RouteComponentProps {
  consumer: Consumer;
  fetchProfile: () => void;
  resetValidation: () => void;
  validated: ValidateResultsState;
  sendError: boolean;
  sendPinToPhone: (data: SendPinProps) => Promise<any>;
  validateSMSPin: (phone: string, pin: string) => Promise<any>;
  updatePhone: (phone: string, consumer: Consumer, validated: ValidateResultsState) => Promise<any>;
}

export function EditPhoneScreen({
  consumer,
  fetchProfile,
  updatePhone,
  resetValidation,
  sendPinToPhone,
  validateSMSPin,
  validated,
  sendError,
  history
}: EditPhoneScreenProps) {
  const [isLoading, setIsLoading] = useState(false);

  const initialPhone = consumer?.phones?.length ? consumer?.phones[0].value : '';

  const data: AmplitudeEventData = {
    currentUrl: 'Edit Phone',
    referringUrl: 'Profile',
    type: 'phone'
  };
  useEffect(() => {
    if (!isConsumerVerified(consumer, validated)) {
      history.push('/u/manage-account');
    }
  }, []);

  const handleCancel = () => {
    history.push('/u/manage-account');
  };

  const handleSubmit = async (pin: string, numberToSubmit: string) => {
    analyticsService.logEvent(AnalyticsEvent.MyAccountEditOptionsEdited, data);

    setIsLoading(true);

    const validateRes = await validateSMSPin(numberToSubmit, pin);
    if (validateRes.error || validateRes?.payload?.data?.success === false) {
      Logger.error(
        `${LoggingModule.profile}SMS verification failed`,
        validateRes?.error?.message || validateRes?.payload?.data?.message
      );
      setIsLoading(false);

      return Alert.alert(
        ProfileUpdateMessages.VALIDATE_PHONE.title,
        ProfileUpdateMessages.VALIDATE_PHONE.message
      );
    }
    const sendValidated: ValidateResultsState = { isFetching: false, results: {} };
    sendValidated.results = cloneDeep(validated.results || {});
    sendValidated.results[numberToSubmit] = { ...validateRes?.payload?.data };

    const res = await updatePhone(numberToSubmit, consumer, sendValidated);

    if (res.error || res?.payload?.data?.success === false) {
      Logger.error(`${LoggingModule.profile}Failed to update phone number`, res.error.message);
      setIsLoading(false);

      return Alert.alert(
        ProfileUpdateMessages.UPDATE_PHONE.title,
        ProfileUpdateMessages.UPDATE_PHONE.message
      );
    }

    setIsLoading(false);

    analyticsService.logEvent(AnalyticsEvent.MyAccountEditOptionsCompleted, data);

    return Alert.alert('Phone Updated', UPDATE_DISCLAIMER, [
      {
        text: 'OK',
        onPress: () => {
          fetchProfile();
          history.goBack();
        }
      }
    ]);
  };

  const props = {
    initialMobile: initialPhone,
    handleCancel,
    handleSubmit,
    isLoading,
    resetValidation,
    validated,
    sendError,
    sendPin: async (data: SendPinProps) => sendPinToPhone(data)
  };
  return <EditPhoneComponent {...props} />;
}

const mapDispatch = (dispatch: Function) => ({
  sendPinToPhone: (data: SendPinProps) =>
    data.phone ? dispatch(sendIDWPin('phone', data.phone)) : null,
  resetValidation: () => dispatch(resetValidationState()),
  validateSMSPin: (phone: string, pin: string) =>
    dispatch(sendIDWVerification('phone', phone, pin)),
  // updatePhone: (update: Consumer) => dispatch(updateConsumerProfile(update, ['Phone'])),
  updatePhone: (phone: string, consumer: Consumer, validated: ValidateResultsState) => {
    const { profileVerifyValue, profileVerifyCode, profileVerifyType } = getProfileVerifyValues(
      consumer,
      validated
    );

    if (validated?.results && profileVerifyValue) {
      const emailObj = validated?.results[phone];
      if (emailObj?.verifyCode) {
        // TODO updateConsumersByIdProfilePhone
        return dispatch(
          updatePhone({
            phone,
            verifyCode: emailObj?.verifyCode,
            profileVerifyValue,
            profileVerifyCode,
            profileVerifyType
          })
        );
      }
    }
    return { error: { message: 'Invalid Verification' } };
  },
  fetchProfile: () => dispatch(getProfile('', true))
});

const mapState = (state: RootState) => ({
  consumer: profileSelectors.profileConsumerSelector(state),
  validated: profileSelectors.profileValidatedSelector(state),
  sendError: profileSelectors.profileValidationSendErrorSelector(state)
});

export default connect(mapState, mapDispatch)(EditPhoneScreen);
