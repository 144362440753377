import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { MuiPaper, MuiBox, dfdDefaultTheme } from 'theme/material-ui';
import FlexBox from 'components/UI/Layout/FlexBox';
import { history } from 'lib/history';
import { Color } from 'modules/styles/colors';

import { RootState } from 'store/types';
import { Consumer } from 'store/profile/types';
import { profileConsumerSelector } from 'store/profile/selectors';
import useNavigationAnalytics from 'hooks/useNavigationAnalytics';
import { AnalyticsEvent } from 'services/AnalyticsService';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { Spacing, BorderRadius, FontWeight, FontSize } from 'modules/styles/variables';
import { Svg } from 'components/UI/Svg';

export interface HealthToolCardData {
  healthToolCardKey: string;
  description: string | ReactElement;
  path: string;
  url?: string;
  thumb: string;
  variant?: string;
  onSelect?: () => void | boolean;
}

export interface HealthToolCardProps {
  toolData: HealthToolCardData;
  userProfile: Consumer;
}

const StyledPaper = styled(MuiPaper)`
  &.MuiPaper-root {
    border-radius: 12px;
  }
`;

const HealthToolItemContainer = styled(MuiBox)`
  cursor: pointer;
  max-width: 340px;
  margin: ${Spacing.large}px;
  ${dfdDefaultTheme.breakpoints.down('lg')} {
    max-width: 300px;
  }
  ${dfdDefaultTheme.breakpoints.down('xs')} {
    max-width: 100%;
    width: 100%;
    margin: ${Spacing.medium}px 0;
  }
`;

const HealthToolItem = styled(FlexBox)`
  ${dfdDefaultTheme.breakpoints.down('xs')} {
    flex-direction: row;
    padding: ${Spacing.medium}px;
  }
`;

const ImgToolContainer = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 12px 12px 0px 0px;
  ${dfdDefaultTheme.breakpoints.down('xs')} {
    width: 115px;
    height: 88px;
    border-radius: ${BorderRadius.base}px;
  }
`;

const DescriptionTool = styled.p`
  color: ${Color.secondary};
  font-weight: ${FontWeight.normal};
  font-size: ${FontSize.base}px;
  min-height: 48px;
  flex: 1;
  margin: ${Spacing.none}px;
  overflow-y: auto;
  ${dfdDefaultTheme.breakpoints.down('xs')} {
    margin: 0 0 0 ${Spacing.medium}px;
    font-size: ${FontSize.base}px;
  }
`;

function HealthToolCard({ toolData, userProfile }: HealthToolCardProps) {
  const vyncaCardClicked = useNavigationAnalytics(AnalyticsEvent.VyncaDashboardSelected, {
    title: 'Health Tools Landing Page',
    vendor: 'Vynca'
  });

  const credidbleEvent = AnalyticsEvent.CredibleMindLaunched;
  const credibleMindClicked = useNavigationAnalytics(credidbleEvent, {
    title: 'Health Tools Landing Page',
    vendor: 'CredibleMind'
  });

  const preventDiabetesCardClicked = useNavigationAnalytics(
    AnalyticsEvent.PreventDiabetesDashboardClicked,
    {
      title: 'Health Tools Landing Page',
      age: userProfile?.age,
      gender: userProfile?.gender
    }
  );

  const shareIdeasButtonClicked = useNavigationAnalytics(AnalyticsEvent.shareIdeasClicked, {
    title: 'Health Tools Landing Page',
    feedbackFormType: 'Health Tools Ideas',
    age: userProfile?.age,
    gender: userProfile?.gender
  });

  // Todo: refactor this, we need to implement a parameter in the tool data with this validations.
  const redirectHealthTool = () => {
    if (toolData.path === '/u/health-tools/vynca') {
      vyncaCardClicked();
      history.push(toolData.path);
    } else if (toolData.path === '/u/health-tools/mystrength') {
      credibleMindClicked();
      if (toolData.url) {
        const newWindow = window.open(toolData.url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      }
    } else if (toolData.path === '/u/health-tools/prevent-diabetes') {
      preventDiabetesCardClicked();
      history.push(toolData.path);
    } else if (toolData.path === '/u/health-tools/survey') {
      shareIdeasButtonClicked();
      if (toolData.onSelect) {
        if (!toolData.onSelect()) history.push(toolData.path);
      }
    } else {
      history.push(toolData.path);
    }
  };

  return (
    <HealthToolItemContainer>
      <StyledPaper>
        <HealthToolItem
          onClick={redirectHealthTool}
          data-testid={convertToLowerKabobCase(toolData.healthToolCardKey)}
        >
          <ImgToolContainer
            alt={toolData.description}
            src={toolData.thumb}
            width="342px"
            height="160px"
          />
          <MuiBox padding="12px 16px">
            <MuiBox display="flex" alignItems="center" justifyContent="space-between">
              <DescriptionTool>{toolData.description}</DescriptionTool>
              {toolData?.variant !== 'd' ? (
                <Svg name="ChevronRight" size={FontSize.base} color={Color.secondary} />
              ) : null}
            </MuiBox>
          </MuiBox>
        </HealthToolItem>
      </StyledPaper>
    </HealthToolItemContainer>
  );
}

const mapStateToProps = (state: RootState) => ({
  userProfile: profileConsumerSelector(state)
});

export default connect(mapStateToProps)(HealthToolCard);
