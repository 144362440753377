import { Alert } from 'components/Alert';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Logger, { LoggingModule } from 'services/Logger';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { ProfileUpdateMessages } from 'store/profile/constants';
import {
  getProfile,
  resetValidationState,
  sendIDWPin,
  sendIDWVerification,
  updateEmail
} from 'store/profile/actions';
import * as profileSelectors from 'store/profile/selectors';
import { getProfileVerifyValues, isConsumerVerified } from 'store/profile/util';
import { Consumer, SendPinProps, ValidateResultsState } from 'store/profile/types';
import { RootState } from 'store/types';
import { UPDATE_DISCLAIMER } from './variables';
import EditEmailComponent from 'components/EditEmail';
import cloneDeep from 'lodash/cloneDeep';

export interface EditEmailScreenProps extends RouteComponentProps {
  consumer: Consumer;
  fetchProfile: () => void;
  resetValidation: () => void;
  validated: ValidateResultsState;
  sendError: boolean;
  sendPinToEmail: (data: SendPinProps) => Promise<any>;
  validateEmailPin: (email: string, pin: string) => Promise<any>;
  updateEmail: (email: string, consumer: Consumer, validated: ValidateResultsState) => Promise<any>;
}

export function EditEmailScreen({
  consumer,
  fetchProfile,
  resetValidation,
  sendError,
  sendPinToEmail,
  updateEmail,
  validateEmailPin,
  validated,
  history
}: EditEmailScreenProps) {
  const [isLoading, setIsLoading] = useState(false);

  const initialEmail = consumer?.emails?.[0]?.value || '';

  useEffect(() => {
    if (!isConsumerVerified(consumer, validated)) {
      history.push('/u/manage-account');
    }
  }, []);

  const data: AmplitudeEventData = {
    currentUrl: 'Edit Email',
    referringUrl: 'Profile',
    type: 'email'
  };

  const handleCancel = () => {
    history.push('/u/manage-account');
  };

  const onSubmit = async (pin: string, emailToSubmit: string) => {
    if (!emailToSubmit) return;

    analyticsService.logEvent(AnalyticsEvent.MyAccountEditOptionsEdited, data);

    setIsLoading(true);

    const validateRes = await validateEmailPin(emailToSubmit, pin);
    if (validateRes.error || validateRes?.payload?.data?.success === false) {
      Logger.error(
        `${LoggingModule.profile}Email verification failed`,
        validateRes?.error?.message || validateRes?.payload?.data?.message
      );

      setIsLoading(false);

      Alert.alert(
        ProfileUpdateMessages.VALIDATE_EMAIL.title,
        ProfileUpdateMessages.VALIDATE_EMAIL.message
      );
    } else {
      const sendValidated: ValidateResultsState = { isFetching: false, results: {} };
      sendValidated.results = cloneDeep(validated.results || {});
      sendValidated.results[emailToSubmit] = { ...validateRes?.payload?.data };
      const res = await updateEmail(emailToSubmit, consumer, sendValidated);

      if (res.error || res?.payload?.data?.success === false) {
        Logger.error(`${LoggingModule.profile}Failed to update email`, res.error.message);

        setIsLoading(false);

        Alert.alert(
          ProfileUpdateMessages.UPDATE_EMAIL.title,
          ProfileUpdateMessages.UPDATE_EMAIL.message
        );
      } else {
        setIsLoading(false);

        analyticsService.logEvent(AnalyticsEvent.MyAccountEditOptionsCompleted, data);

        Alert.alert('Email Updated', UPDATE_DISCLAIMER, [
          {
            text: 'OK',
            onPress: () => {
              fetchProfile();
              history.push('/u/manage-account');
            }
          }
        ]);
      }
    }
  };

  const props = {
    initialEmail,
    resetValidation,
    sendError,
    sendPin: async (data: SendPinProps) => sendPinToEmail(data),
    validated,
    onSubmit,
    handleCancel,
    isLoading
  };
  return <EditEmailComponent {...props} />;
}

const mapDispatch = (dispatch: Function) => ({
  resetValidation: () => dispatch(resetValidationState()),
  sendPinToEmail: (data: SendPinProps) =>
    data.email ? dispatch(sendIDWPin('email', data.email)) : null,
  validateEmailPin: (email: string, pin: string) =>
    dispatch(sendIDWVerification('email', email, pin)),
  updateEmail: (email: string, consumer: Consumer, validated: ValidateResultsState) => {
    const { profileVerifyValue, profileVerifyCode, profileVerifyType } = getProfileVerifyValues(
      consumer,
      validated
    );

    if (validated?.results && profileVerifyValue) {
      const emailObj = validated?.results[email];
      if (emailObj?.verifyCode) {
        return dispatch(
          updateEmail({
            email,
            verifyCode: emailObj?.verifyCode,
            profileVerifyValue,
            profileVerifyCode,
            profileVerifyType
          })
        );
      }
    }
    return { error: { message: 'Invalid Verification' } };
  },
  fetchProfile: () => dispatch(getProfile('', true))
});

const mapState = (state: RootState) => ({
  consumer: profileSelectors.profileConsumerSelector(state),
  validated: profileSelectors.profileValidatedSelector(state),
  sendError: profileSelectors.profileValidationSendErrorSelector(state)
});

export default connect(mapState, mapDispatch)(EditEmailScreen);
