import React, { MutableRefObject, useState, createContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { MuiAccordion, MuiAccordionDetails, MuiAccordionSummary, MuiBox } from 'theme/material-ui';
import { Svg } from 'components/UI/Svg';

import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import { IconSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';

export const ExpandedAccordionContext = createContext();

const useStyles = makeStyles({
  root: {
    backgroundColor: props => props.titleBackgroundColor
  },
  content: { backgroundColor: props => props.contentBackgroundColor },
  accordionShadow: {
    boxShadow: props => props.shadow
  }
});

export interface Props {
  children?: JSX.Element[] | JSX.Element | React.ReactNode;
  isExpandedByDefault?: boolean;
  isExpandable?: boolean;
  title: string;
  testId?: string;
  analyticsEvent?: AnalyticsEvent;
  currentUrlForAnalytics?: string;
  referringUrlForAnalytics?: string;
  titleBackgroundColor?: string;
  contentBackgroundColor?: string;
  square?: boolean;
  shadow?: string;
  onOpen?: () => void;
  refProp?: MutableRefObject<null> | null;
}

const Accordion = ({
  children,
  isExpandedByDefault,
  isExpandable,
  title,
  testId,
  analyticsEvent,
  currentUrlForAnalytics,
  referringUrlForAnalytics,
  square,
  titleBackgroundColor,
  contentBackgroundColor,
  shadow,
  onOpen,
  refProp
}: Props) => {
  const [expanded, setExpanded] = useState(isExpandedByDefault);
  const classes = useStyles({ titleBackgroundColor, contentBackgroundColor, shadow });

  const onItemExpand = () => {
    if (analyticsEvent && !expanded) {
      const data: AmplitudeEventData = {
        currentUrl: currentUrlForAnalytics,
        referringUrl: referringUrlForAnalytics
      };

      analyticsService.logEvent(analyticsEvent, data);
    }

    if (onOpen) {
      onOpen();
    }

    setExpanded(!expanded);
  };

  return (
    <ExpandedAccordionContext.Provider value={[expanded, setExpanded]}>
      <MuiAccordion
        disabled={!isExpandable}
        defaultExpanded={isExpandedByDefault}
        onChange={onItemExpand}
        data-testid={testId}
        square={square}
        expanded={expanded}
        // possible future performance enhancement
        // see: https://material-ui.com/components/accordion/#performance
        // TransitionProps={{ unmountOnExit: true }}
        classes={{ root: classes.accordionShadow }}
      >
        <MuiAccordionSummary
          expandIcon={
            <Svg set="assets" name="ChevronDown" size={IconSize.base} color={Color.black} />
          }
          classes={{ root: classes.root }}
        >
          <MuiBox py={1} data-testid={convertToLowerKabobCase(title, '-expandable-drawer')}>
            {title}
          </MuiBox>
        </MuiAccordionSummary>
        <MuiAccordionDetails ref={refProp} classes={{ root: classes.content }}>
          <MuiBox width="100%">{children}</MuiBox>
        </MuiAccordionDetails>
      </MuiAccordion>
    </ExpandedAccordionContext.Provider>
  );
};

Accordion.defaultProps = {
  children: null,
  isExpandable: true,
  testId: 'accordion',
  title: ''
};

export default Accordion;
