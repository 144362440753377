import { Alert } from 'components/Alert';
import dayjs from 'dayjs';
import { Nullable } from 'modules/types/common';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action, Dispatch } from 'redux';
import { PathType } from 'store/pageActions/types';
import { bootstrapApp, getDeps } from 'store/authentication/bootstrap-app';
import { settingsErrorSelector, settingsLoadedSelector } from 'store/appSettings/selectors';
import { logout } from 'store/authentication/actions';
import { AuthResult, TpsStatus } from 'store/authentication/reducers';
import {
  dfdAuthenticationSelector,
  authenticationErrorSelector,
  authNavStateSelector,
  tokenVerifySelector
} from 'store/authentication/selectors';
import { globalErrorSelector, loginTypeSelector } from 'store/global/selectors';
import { navigationWebStateChange } from 'store/navigation/actions';
import { RootState } from 'store/types';
import { getPathtype } from './pathFinder';
import usePageVisibility from 'lib/hooks/usePageVisibility';
import { handleUnauthenticated, handleAuthentication, handleAuthenticatedComplete } from './util';
import { LoginType } from 'store/authentication/types';
import { LoadingAlert } from 'components/Alert/loading-alert';
import { FetchingReducerState } from 'shared/src/store/setLocalStore';
import { HELP_SCREEN_LINKS } from 'lib/constants/help';
import { profileErrorSelector } from 'store/profile/selectors';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

const loadStart = Date.now();

interface Props {
  authError: Error | null;
  apiKey: string;
  children?: React.ReactNode;
  dfdAuth?: AuthResult | null;
  getDepsError?: Nullable<Error>;
  hasSettings: boolean;
  hasFetchedDependencies: boolean;
  isAuthenticated: boolean;
  isFetchingDependencies: boolean;
  loginType?: LoginType;
  loadApp: typeof bootstrapApp;
  logoutApp: typeof logout;
  navAuthState: 'loading' | 'error' | 'unauthenticated' | 'authenticated' | 'loaded';
  pathAfterLogin?: string;
  pathname: string;
  profileError: Nullable<Error>;
  setNavState: typeof navigationWebStateChange;
  settingsError: Nullable<Error>;
  tokenVerify: FetchingReducerState<{ active: boolean }>;
  tpsAuthStatus: TpsStatus[] | null;
}

export function handleExpired(
  dfdAuth: AuthResult | null,
  dispatch: Dispatch<Action>,
  loadStart: number
) {
  // detect auth expired from inactivity.
  const tenMinutesSinceLoad = Date.now() - loadStart > 600000; // Ten minutes is not arbitrary. If an active user is using the app the refresh token will activate 10 minutes before the token expires.
  const expired =
    dfdAuth && !dayjs(dfdAuth.accessTokenExpirationDate).isAfter(dayjs().add(1, 'minute'));

  if (tenMinutesSinceLoad && expired) {
    localStorage.setItem('pathAfterLogin', '/u/dashboard');
    Alert.alert('Session Timeout', 'Your session has ended, please log in again.', [
      { text: 'OK', onPress: () => dispatch(logout()) }
    ]);
  }
}

/**
 * Method: NavState
 * Description: Handles loading error states including expired and kicks off bootstrapApp if the app has not yet loaded.
 * @param param0
 */
export const NavState = ({
  authError,
  children,
  dfdAuth,
  getDepsError,
  hasSettings,
  loginType,
  navAuthState,
  pathname,
  profileError,
  settingsError,
  tokenVerify
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isHidden = usePageVisibility();
  const [openAlert, setOpenAlert] = useState(false);
  const [errorCode, setErrorCode] = useState<string>();

  useEffect(() => {
    const pathType: PathType = getPathtype(pathname);

    if (navAuthState === 'error') {
      // handle error
      if (authError) {
        // Token has expired. Send back to landing
        handleUnauthenticated(history, dispatch, pathType, pathname);
      }
    } else if (navAuthState === 'unauthenticated') {
      handleUnauthenticated(history, dispatch, pathType, pathname);
    } else if (navAuthState === 'authenticated') {
      handleAuthentication(pathname, dispatch, dfdAuth);
    } else if (navAuthState === 'loaded') {
      handleAuthenticatedComplete(dispatch, history, pathType, pathname);
    }
  }, [navAuthState]);

  useEffect(() => {
    // Super hacks for CCBH and VPC
    if (pathname?.includes('connect-care/CCBH')) {
      window.localStorage.setItem('ccBrand', 'CCBH');
      pathname = pathname.replace('/CCBH', '');
    } else if (pathname?.includes('connect-care/VPC')) {
      window.localStorage.setItem('ccBrand', 'VPC');
      pathname = pathname.replace('/VPC', '');
    } else if (pathname?.includes('connect-care/CCEV')) {
      window.localStorage.setItem('ccBrand', 'CCEV');
      pathname = pathname.replace('/CCEV', '');
    }

    if (getDepsError) {
      setErrorCode('L-DEP');
    } else if (settingsError) {
      setErrorCode('L-APS');
    } else if (authError || tokenVerify?.error) {
      setErrorCode('L-ATH');
    } else if (profileError) {
      setErrorCode('L-PRO');
    } else if (!hasSettings) {
      dispatch(navigationWebStateChange('preload'));
      dispatch(bootstrapApp()); // { pathname, pathType, history }
    }

    if (getDepsError || settingsError || authError || tokenVerify?.error || profileError) {
      if (!openAlert) {
        setOpenAlert(true);
      }
    }
  }, [authError, getDepsError, hasSettings, settingsError, profileError]);

  useEffect(() => {
    handleExpired(dfdAuth, dispatch, loadStart);
  }, [dfdAuth, dispatch, isHidden, tokenVerify]);

  const handleTryAgain = () => {
    // handle deps error
    if (errorCode === 'L-DEP') {
      getDeps(dispatch);
      history.push('/loading');
    } else if (errorCode === 'L-ATH') {
      // handle auth error or inactive auth
      dispatch(logout());
      // handle settings error
    } else {
      dispatch(bootstrapApp());
    }
  };

  const handleClose = (action: 'close' | 'tryAgain' | 'contact') => {
    analyticsService.logEvent(AnalyticsEvent.SignInFailed, { errorCode, action });
    setOpenAlert(false);
    if (action === 'tryAgain') {
      analyticsService.logEvent(AnalyticsEvent.SignInFailedTryAgainClicked, { errorCode });
      handleTryAgain();
    } else if (action === 'contact') {
      analyticsService.logEvent(AnalyticsEvent.SignInFailedContactClicked, { errorCode });
      hanldeContact();
    } else {
      analyticsService.logEvent(AnalyticsEvent.SignInFailedCloseClicked, { errorCode });
      if (errorCode === 'L-ATH' || errorCode === 'L-DEP') {
        dispatch(logout());
      } else {
        dispatch(bootstrapApp());
      }
    }
  };

  const hanldeContact = () => {
    dispatch(logout(HELP_SCREEN_LINKS.Contact.webPath));
  };

  return (
    <>
      <>
        <LoadingAlert errorCode={errorCode} open={openAlert} handleClose={handleClose} />
      </>
      {children}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  authError: authenticationErrorSelector(state),
  loginType: loginTypeSelector(state),
  navAuthState: authNavStateSelector(state),
  dfdAuth: dfdAuthenticationSelector(state),
  getDepsError: globalErrorSelector(state),
  profileError: profileErrorSelector(state),
  hasSettings: settingsLoadedSelector(state),
  tokenVerify: tokenVerifySelector(state),
  settingsError: settingsErrorSelector(state)
});

export default connect(mapStateToProps)(NavState);
