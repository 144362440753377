import React from 'react';
import * as MaterialIcons from '@material-ui/icons';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import MaterialIcon from '@material-ui/core/Icon';
import { Color } from 'modules/styles/colors';
import { IconSize } from 'modules/styles/variables';

export interface IconProps {
  accessibilityLabel?: string;
  fontSize?: 'inherit' | 'default' | 'small' | 'large';
  color?: string;
  size?: number;
  name: string;
  onClick?: () => void;
}

const pascalCase = (name: string) => upperFirst(camelCase(name));

export const Icon = ({ name, color = Color.gray, size = IconSize.base, ...rest }: IconProps) => {
  const normalizedName = pascalCase(name) as keyof typeof MaterialIcons;

  const IconComponent = MaterialIcons[normalizedName] as typeof MaterialIcon;

  if (!IconComponent) {
    return null;
  }

  return <IconComponent {...rest} style={{ color, fontSize: size }} />;
};
