import React, { useState } from 'react';
import styled from 'styled-components';
import TermsAndConditionsModal from 'screens/TermsAndConditions/TermsAndConditions';
import { Confirm } from 'components/ConfirmDialog/ConfirmDialog';
import { StyledAsterisk } from 'components/RequiredFieldsLegend';
import {
  MuiBox,
  MuiContainer,
  MuiTypography,
  MuiButton,
  MuiSelect,
  MuiMenuItem,
  MuiFormControl
} from 'theme/material-ui';
import {
  eVisitFrontDoorTermsAndAgreementsScreen,
  eVisitNotInUtahDialogConstants,
  EVISIT_STATES,
  eVisitFrontDoorOverviewScreen
} from 'lib/triage/constants';
import { Color } from 'modules/styles/colors';
import { Spacing } from 'modules/styles/variables';
import { TermsAndConditionsType } from 'store/global/reducers';
import EVisitBanner from './components/EVisitBanner';
import EVisitCheckboxItem from './components/EVisitCheckboxItem';
import { EVISITS_ROUTES } from '../constants';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { acceptEVisitTermsOfUse } from 'store/profile/actions';
import { resetTriageData } from 'store/triageGyant/actions';
import { useLocalStorage } from 'hooks/useLocalStorage';

const StyledSelect = styled(MuiSelect)`
  background-color: ${Color.white};
  box-shadow: 0 4px 14px rgb(0 0 0 / 10%);
  fieldset {
    border: 0;
  }
  .MuiSelect-root:focus {
    background-color: ${Color.white};
  }
`;

const EVisitTermsAndAgreements = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [consentTreat, setConsentTreat] = useState(false);
  const [privacyPractices, setPrivacyPractices] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [stateSelected, setStateSelected] = useState('');
  const [termsType, setTermsType] = useState<TermsAndConditionsType>();
  const [eVisitInfo, setEVisitInfo] = useLocalStorage('EvisitCache', '');
  const dispatch = useDispatch();

  const onClickNext = async () => {
    setIsLoading(true);

    await dispatch(resetTriageData());
    const resp = await dispatch(acceptEVisitTermsOfUse());
    setIsLoading(false);

    if (resp?.error) {
      Confirm.show(
        'Unable to start E-Visit at this time',
        'Please try again or contact support.',
        '',
        'error',
        {
          text: 'Ok'
        }
      );
    } else {
      setEVisitInfo(JSON.stringify({ localStateSelected: stateSelected }));

      history.push(EVISITS_ROUTES.FRONTDOOR_CONDITIONS_WE_TREAT, {
        stateSelected
      });
    }
  };

  const showNotInAreaDialog = () => {
    Confirm.show(
      eVisitNotInUtahDialogConstants.dialogTitleText,
      eVisitNotInUtahDialogConstants.dialogContentText,
      '',
      'info'
    );
  };

  return (
    <>
      <EVisitBanner title={eVisitFrontDoorTermsAndAgreementsScreen.screenTitle} />
      <MuiContainer>
        <TermsAndConditionsModal
          declineAction={() => setTermsType(undefined)}
          open={!!termsType}
          type={termsType}
        />
        <MuiBox
          data-testid="e-visit-terms-and-agreements"
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingY={2}
          marginY={Spacing.small}
          maxWidth={480}
          marginX="auto"
        >
          <MuiBox paddingY={Spacing.small}>
            <MuiTypography fontSize={18}>
              {eVisitFrontDoorTermsAndAgreementsScreen.headerText}{' '}
              <MuiTypography
                color={Color.link}
                display="inline"
                onClick={() => showNotInAreaDialog()}
                style={{ cursor: 'pointer' }}
              >
                {eVisitFrontDoorTermsAndAgreementsScreen.notInUtah}
              </MuiTypography>
            </MuiTypography>
          </MuiBox>
          <MuiBox width="100%" paddingBottom={Spacing.small}>
            <MuiTypography>
              Select a State <StyledAsterisk />
            </MuiTypography>
            <MuiBox padding={Spacing.xSmall}>
              <MuiFormControl fullWidth variant="outlined">
                <StyledSelect
                  onChange={e => setStateSelected(e.target.value as string)}
                  data-testid="select-a-state"
                  defaultValue=""
                  displayEmpty
                  value={stateSelected}
                >
                  <MuiMenuItem value="" disabled>
                    Select State
                  </MuiMenuItem>
                  {EVISIT_STATES.map(({ label, value }) => {
                    return (
                      <MuiMenuItem key={value} value={value} data-testid={label}>
                        {label}
                      </MuiMenuItem>
                    );
                  })}
                </StyledSelect>
              </MuiFormControl>
            </MuiBox>
            <MuiBox marginY={2}>
              <EVisitCheckboxItem
                testID="consent"
                label={eVisitFrontDoorTermsAndAgreementsScreen.consentCheckbox}
                linkText={eVisitFrontDoorTermsAndAgreementsScreen.consentCheckboxLink}
                onChange={(e, checked: boolean) => setConsentTreat(checked)}
                onClick={() => setTermsType(TermsAndConditionsType.E_VISIT_CONSENT_TO_TREAT)}
              />
            </MuiBox>
            <MuiBox marginY={2}>
              <EVisitCheckboxItem
                testID="privacy"
                label={eVisitFrontDoorTermsAndAgreementsScreen.noticeCheckbox}
                linkText={eVisitFrontDoorTermsAndAgreementsScreen.noticeCheckboxLink}
                onChange={(e, checked: boolean) => setPrivacyPractices(checked)}
                onClick={() => setTermsType(TermsAndConditionsType.E_VISIT_PRIVACY_NOTICE)}
              />
            </MuiBox>
            <MuiBox marginY={2} marginBottom={Spacing.medium}>
              <EVisitCheckboxItem
                testID="terms"
                label={eVisitFrontDoorTermsAndAgreementsScreen.termsOfUseCheckbox}
                linkText={eVisitFrontDoorTermsAndAgreementsScreen.termsOfUseCheckboxLink}
                onChange={(e, checked: boolean) => setTermsOfUse(checked)}
                onClick={() => setTermsType(TermsAndConditionsType.E_VISIT_TERMS_OF_USE)}
              />
            </MuiBox>
            <MuiBox>
              <MuiTypography color={Color.red} style={{ textAlign: 'center' }}>
                {eVisitFrontDoorOverviewScreen.disclosures.age}
              </MuiTypography>
            </MuiBox>
            <MuiBox my={Spacing.xSmall}>
              <MuiBox mx="auto" padding={Spacing.small} maxWidth="250px">
                <MuiButton
                  fullWidth
                  data-testid="next-button"
                  variant="contained"
                  color="primary"
                  size="large"
                  loading={isLoading}
                  disabled={!consentTreat || !privacyPractices || !termsOfUse || !stateSelected}
                  onClick={onClickNext}
                >
                  Next
                </MuiButton>
              </MuiBox>
            </MuiBox>
          </MuiBox>
        </MuiBox>
      </MuiContainer>
    </>
  );
};

export default EVisitTermsAndAgreements;
