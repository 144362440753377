import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Config from 'react-native-config';

import {
  MuiBox,
  MuiContainer,
  MuiDivider,
  MuiGrid,
  MuiTypography,
  MuiRadio,
  MuiFormControlLabel
} from 'theme/material-ui';

import {
  fullName,
  TimerContext,
  BOOKING_TIMEOUT,
  APPOINTMENT_REASON_MAX_LENGTH,
  calculateGestationalWeeks,
  calculateChildAge
} from 'lib/booking/utils';

import { Color } from 'modules/styles/colors';
import { reasonForVisit } from 'modules/constants/booking';
import { BorderRadius, FontSize, FontWeight } from 'modules/styles/variables';
import { isPrimaryUser } from 'modules/utils/UserUtils';

import { RootState } from 'store/types';
import * as bookingTypes from 'store/booking/types';
import * as bookingActions from 'store/booking/actions';
import * as bookingSelectors from 'store/booking/selectors';

import TextInputCounter from '../../../components/UI/Input/TextInputCounter';
import { WCC_VISIT_TYPE_IDS, WCE_VISIT_TYPE_IDS } from 'store/booking/constants';
import { listItemCircle } from '../components/sharedComponents';
import { StyledUL, StyledScreen } from '../components/styled';
import { useCheckBookingInfoEffect } from '../useCheckBookingInfoEffect';
import { BookingStepActions, BookingStepAction } from '../components/BookingStepper';
import BookingTimer from '../components/BookingTimer/BookingTimer';
import FlexBox from 'components/UI/Layout/FlexBox';

export interface Props extends RouteComponentProps {
  appointmentDetails: bookingTypes.AppointmentDetails;
  setReasonForVisit: typeof bookingActions.setReasonForVisit;
  visitType: bookingTypes.VisitType;
  selectedSpecialty: bookingTypes.Specialty;
  unlockTimeSlot: typeof bookingActions.unlockTimeSlot;
}

export function BookingReasonForVisit({
  appointmentDetails,
  setReasonForVisit,
  visitType,
  selectedSpecialty,
  history,
  unlockTimeSlot
}: Props) {
  const { lockedSlot } = history?.location?.state ?? {};
  const [, setTimerContext] = useContext(TimerContext);
  const [isPediatrics, setIsPediatrics] = useState(false);
  const [isFamilyMed, setIsFamilyMed] = useState(false);
  const [initialComment, setInitialComment] = useState('');
  const [reasonForVisitText, setVisitReasonText] = useState('');
  const [paymentSelection, setPaymentSelection] = useState('');
  const { ObGynVisitType } = bookingTypes;

  useCheckBookingInfoEffect(appointmentDetails?.patient);

  useEffect(() => {
    const {
      appointmentDueDate,
      patient,
      appointmentDate,
      groupedVisitType,
      reasonForVisit = ''
    } = appointmentDetails;

    const visitTypeId = groupedVisitType?.visitTypes[0].visitTypeId;
    const hasDateOfBirth = !!patient?.dateOfBirth;

    if (
      !isFamilyMed &&
      hasDateOfBirth &&
      matchesVisitType(visitTypeId, [WCE_VISIT_TYPE_IDS[1], WCC_VISIT_TYPE_IDS[1]])
    ) {
      setIsFamilyMed(true);
    }
    if (
      !isPediatrics &&
      Config.PEDIATRICS === 'enabled' &&
      hasDateOfBirth &&
      matchesVisitType(visitTypeId, [WCE_VISIT_TYPE_IDS[0], WCC_VISIT_TYPE_IDS[0]])
    ) {
      setIsPediatrics(true);
    }

    let initialCommentMessage = '';

    if (appointmentDueDate && groupedVisitType?.name === ObGynVisitType.prenatal) {
      initialCommentMessage = calculateGestationalWeeks(appointmentDate, appointmentDueDate);
    } else if (isPediatrics || isFamilyMed) {
      initialCommentMessage = calculateChildAge(appointmentDate, patient?.dateOfBirth);
    }
    setInitialComment(initialCommentMessage);
    if (!reasonForVisit.includes('Payment selection:')) setVisitReasonText(reasonForVisit);
  }, [isPediatrics, isFamilyMed]);

  const matchesVisitType = (typeId, ids) => {
    return ids.includes(typeId);
  };

  const isPrimary = isPrimaryUser(appointmentDetails?.patient);
  const isPrimarySubtitleText = isPrimary
    ? 'What is the reason for your appointment?'
    : `What is the reason for ${fullName(appointmentDetails?.patient)} appointment?`;
  const isPrimaryLabelText = isPrimary ? 'Describe your symptoms' : 'Describe the symptoms';
  const titleText = appointmentDetails?.isEditingAppointment
    ? 'Reason for Reschedule'
    : 'Reason for Appointment';
  const subtitleText = appointmentDetails?.isEditingAppointment ? '' : isPrimarySubtitleText;
  const labelText = appointmentDetails?.isEditingAppointment
    ? 'What is the reason for your visit?'
    : isPrimaryLabelText;

  const handleNextClick = () => {
    const paymentSelectionMessage =
      paymentSelection === 'self pay'
        ? 'Payment selection: self pay. '
        : 'Payment selection: insured. ';
    const message = `${reasonForVisitText}. ${initialComment}${paymentSelectionMessage}`;
    setReasonForVisit(message.trim());
    setVisitReasonText('');
    history.push('/u/get-care-now/booking/review-appointment-details', {
      lockedSlot
    });
  };

  const handlePreviousClick = () => {
    setTimerContext(BOOKING_TIMEOUT);
    if (lockedSlot) {
      unlockTimeSlot(lockedSlot);
    }
    history.goBack();
  };

  return (
    <StyledScreen>
      <FlexBox flex="1">
        <MuiContainer>
          <MuiBox my={3}>
            <MuiGrid container>
              <MuiGrid item xs>
                <MuiTypography variant="h4">{titleText}</MuiTypography>
              </MuiGrid>
            </MuiGrid>
            <MuiBox my={3}>
              <MuiDivider />
            </MuiBox>
            <MuiGrid container xs justify="center">
              <MuiGrid item sm={3} />
              <MuiGrid item sm={6}>
                {visitType?.specialty?.nameShort === 'PS' ||
                selectedSpecialty?.nameShort?.includes('PS') ? (
                  <>
                    <MuiBox pb={2}>
                      <MuiTypography fontSize={FontSize.large}>
                        {reasonForVisit.BEHAVIORAL_HEALTH_PARAGRAPH_ONE}
                      </MuiTypography>
                    </MuiBox>
                    <MuiBox bgcolor={Color.cobaltTint} borderRadius={BorderRadius.base} p={2}>
                      <StyledUL>
                        {reasonForVisit.BEHAVIORAL_HEALTH_CONDITIONS.map((item: string) =>
                          listItemCircle(item, FontSize.large)
                        )}
                      </StyledUL>
                    </MuiBox>
                    <MuiBox pt={2} pb={3}>
                      <MuiTypography fontSize={FontSize.large}>
                        {reasonForVisit.BEHAVIORAL_HEALTH_PARAGRAPH_TWO}
                      </MuiTypography>
                    </MuiBox>
                    <MuiBox pt={2} pb={3}>
                      <MuiTypography fontSize={FontSize.large}>
                        {reasonForVisit.BEHAVIORAL_HEALTH_PARAGRAPH_THREE}
                      </MuiTypography>
                    </MuiBox>
                  </>
                ) : null}
                <MuiTypography variant="h6" fontWeight={FontWeight.regularbold} align="left">
                  {subtitleText}
                </MuiTypography>
                <MuiBox my={1}>
                  <MuiTypography variant="body1" align="left">
                    {labelText} <span style={{ color: 'red' }}>*</span>
                  </MuiTypography>
                </MuiBox>
                <TextInputCounter
                  value={reasonForVisitText}
                  maxLength={APPOINTMENT_REASON_MAX_LENGTH}
                  data-testid="reasonInput"
                  multiline
                  rows={3}
                  fullWidth
                  placeholder="Enter comment"
                  variant="outlined"
                  color="secondary"
                  onTextChange={(text: string) =>
                    setVisitReasonText(text.substring(0, APPOINTMENT_REASON_MAX_LENGTH))
                  }
                />
              </MuiGrid>
              <MuiGrid item sm={3} />
            </MuiGrid>
          </MuiBox>
          <MuiGrid container xs justify="center">
            <MuiGrid item sm={3} />
            <MuiGrid item sm={6}>
              <MuiBox marginTop={2} marginBottom={4}>
                <MuiTypography variant="h6" fontWeight={FontWeight.regularbold} align="left">
                  How would you like your payment processed?
                </MuiTypography>
                <MuiBox marginLeft={2} marginTop={2}>
                  <MuiBox>
                    <MuiFormControlLabel
                      data-testid="self-pay"
                      control={
                        <MuiRadio
                          color="secondary"
                          value="self pay"
                          onChange={() => setPaymentSelection('self pay')}
                          checked={paymentSelection === 'self pay'}
                        />
                      }
                      label="Self Pay"
                    />
                  </MuiBox>
                  <MuiBox marginBottom={3}>
                    <MuiFormControlLabel
                      data-testid="bill-insurance"
                      control={
                        <MuiRadio
                          color="secondary"
                          value="bill insurance"
                          onChange={() => setPaymentSelection('bill insurance')}
                          checked={paymentSelection === 'bill insurance'}
                        />
                      }
                      label="Bill Insurance"
                    />
                  </MuiBox>
                  <MuiTypography variant="body1" align="left">
                    You may get a phone call for your cost estimation.
                  </MuiTypography>
                </MuiBox>
              </MuiBox>
            </MuiGrid>
            <MuiGrid item sm={3} />
          </MuiGrid>
        </MuiContainer>
      </FlexBox>

      <BookingTimer />
      <BookingStepActions>
        <BookingStepAction
          data-testid="next-button"
          disabled={!reasonForVisitText.trim() || !paymentSelection}
          onClick={handleNextClick}
        />
        <BookingStepAction data-testid="previous-button" onClick={handlePreviousClick} />
      </BookingStepActions>
    </StyledScreen>
  );
}

const mapStateToProps = (state: RootState) => ({
  appointmentDetails: bookingSelectors.appointmentDetailsSelector(state),
  visitType: bookingSelectors.visitTypeSelector(state),
  selectedSpecialty: bookingSelectors.selectedSpecialtySelector(state)
});

const mapDispatchToProps = {
  setReasonForVisit: bookingActions.setReasonForVisit,
  unlockTimeSlot: bookingActions.unlockTimeSlot
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingReasonForVisit);
