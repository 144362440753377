import React, { useEffect, useState } from 'react';
import { oc } from 'ts-optchain';
import Config from 'react-native-config';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Svg from 'components/UI/Svg/Svg';
import UserAvatar from 'components/UI/Avatar';
import Spinner from 'components/UI/Spinner/Spinner';
import { Modal } from 'components/CustomModal';
import {
  CursorMuiBox,
  MuiBox,
  MuiCard,
  MuiCardContent,
  MuiGrid,
  MuiLink,
  MuiSwitch,
  MuiTypography
} from 'theme/material-ui';
import { getUserName } from 'lib/user';
import { Color } from 'modules/styles/colors';
import { IconSize } from 'modules/styles/variables';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';
import { RootDispatch, RootState } from 'store/types';
import { Consumer } from 'store/profile/types';
import { consumerAvatarSelector } from 'store/consumerPreferences/selectors';
import { profileConsumerSelector } from 'store/profile/selectors';
import { sendIDWPin, updateConsumerMfaEnabledFlag } from 'store/profile/actions';
import analyticsService, { AMPLITUDE_EXPERIMENTS, AnalyticsEvent } from 'services/AnalyticsService';

interface MyProfileProps {
  consumer: Consumer;
  consumerAvatar: string;
  updateConsumerMfaFlag: typeof updateConsumerMfaEnabledFlag;
}

interface MyProfileTile {
  title: string;
  icon: string;
  onClick?: () => void;
  onToggle?: () => void;
  href?: string;
  children?: React.ReactNode;
  isToggled?: boolean;
  isProcessingInOtherTab?: boolean;
}

const CHANGE_PASSWORD_LINK = `${Config.AUTH_SERVER_BASE_URL}${Config.AUTH_END_POINT_CHANGE_PASSWORD}`;
const AVATAR_FEAT_ENABLED = Config.AVATAR === 'enabled';

export const MyProfileTile = ({
  title,
  icon,
  onClick,
  onToggle,
  children,
  href,
  isToggled,
  isProcessingInOtherTab
}: MyProfileTile) => {
  return (
    <MuiBox display="flex" p={2} alignItems="flex-start">
      <MuiBox flex={0}>
        <Svg set="assets" name={icon} aria-hidden="true" size={IconSize.base} />
      </MuiBox>

      <MuiBox
        data-testid={convertToLowerKabobCase(title)}
        flexGrow={1}
        flexShrink={0}
        flexBasis="auto"
        mx={2}
      >
        <MuiTypography color={Color.textLight} gutterBottom>
          {title}
        </MuiTypography>

        {children}
      </MuiBox>

      {onClick ? (
        <MuiBox flex={0}>
          <MuiLink onClick={onClick} data-testid={`edit-${convertToLowerKabobCase(title)}`}>
            Edit
          </MuiLink>
        </MuiBox>
      ) : null}

      {href ? (
        <MuiBox flex={0}>
          <MuiLink href={href} target="_blank" rel="noopener noreferrer">
            Change
          </MuiLink>
        </MuiBox>
      ) : null}
      {onToggle && isProcessingInOtherTab ? <Spinner /> : null}
      {onToggle && !isProcessingInOtherTab ? (
        <MuiBox display="flex" alignItems="center">
          {isToggled ? <MuiTypography color={Color.primary}>Enrolled</MuiTypography> : null}
          <MuiSwitch
            checked={isToggled}
            onChange={onToggle}
            aria-label="Toggle Two-Factor Authentication"
            data-testid="toggle-two-factor"
            color="primary"
          />
        </MuiBox>
      ) : null}
    </MuiBox>
  );
};

export const MyProfile = ({ consumer, consumerAvatar, updateConsumerMfaFlag }: MyProfileProps) => {
  const history = useHistory();
  const address = oc(consumer).addresses[0]();
  const phone = oc(consumer).phones[0]();
  const email = oc(consumer).emails[0]();
  const consumerName = getUserName(consumer);

  const handleAvatarClick = () => {
    history.push('/u/manage-account/manage-avatars');
  };

  const [isProcessingInOtherTab, setIsProcessingInOtherTab] = useState(false);

  const handlePingTwoFactorPopup = () => {
    if (consumer.mfaEnabled) {
      // Turn off:
      Modal.show({
        title: 'Two-step verification',
        description: 'Are you sure you want to disable Two-step verification?',
        buttons: {
          styles: { direction: 'row', justifyContent: 'center', alignItems: 'center' },
          items: [
            {
              label: 'Confirm',
              onClick: () => {
                updateConsumerMfaFlag(false);
                analyticsService.logEvent(AnalyticsEvent.TwoStepLoginOff);
              },
              styles: { paddingX: 20, height: 55, width: 120 }
            },
            {
              label: 'Cancel',
              onClick: () => {},
              styles: { paddingX: 20, height: 55, width: 120, variant: 'outline' }
            }
          ]
        }
      });
    } else {
      // Turn on:
      const redirect = `${Config.AUTH_SERVER_BASE_URL}/idp/startSSO.ping?PartnerSpId=pingid-registration-ih`;
      window.open(redirect, '_blank');
      setIsProcessingInOtherTab(true);
    }
    analyticsService.logEvent(AnalyticsEvent.TwoStepLoginToggled);
  };

  const [showTwoStepToggle, setShowTwoStepToggle] = useState(false);

  useEffect(() => {
    const twoStepLoginActive = analyticsService.getExperimentStatus(
      AMPLITUDE_EXPERIMENTS.TWO_STEP_LOGIN.flagKey,
      AMPLITUDE_EXPERIMENTS.TWO_STEP_LOGIN.variantA
    );
    setShowTwoStepToggle(twoStepLoginActive);
  }, []);

  return (
    <MuiBox>
      <MuiCard>
        <MuiCardContent data-testid="profile-card">
          <MuiBox display="flex" flexDirection="row">
            {AVATAR_FEAT_ENABLED && (
              <CursorMuiBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                mr={4}
                onClick={handleAvatarClick}
                data-testid="my-profile-avatar"
              >
                <UserAvatar avatarUrl={consumerAvatar} fallback={consumerName} size={70} />
                <MuiBox pt={1}>
                  <MuiTypography align="center" color={Color.secondary}>
                    Manage Avatars
                  </MuiTypography>
                </MuiBox>
              </CursorMuiBox>
            )}
            <MuiBox width={AVATAR_FEAT_ENABLED ? '84%' : '100%'}>
              <MuiTypography data-testid="name" gutterBottom>
                {consumer.firstName} {consumer.lastName}
              </MuiTypography>

              <MuiGrid container spacing={3}>
                {consumer.dateOfBirth ? (
                  <MuiGrid item lg={4} md={6} xs={12}>
                    <MuiTypography>Birthday</MuiTypography>
                    <MuiTypography data-testid="birthday">{consumer.dateOfBirth}</MuiTypography>
                  </MuiGrid>
                ) : null}

                {consumer.ethnicity ? (
                  <MuiGrid item lg={4} md={6} xs={12}>
                    <MuiTypography>Race</MuiTypography>
                    <MuiTypography data-testid="race">{consumer.ethnicity}</MuiTypography>
                  </MuiGrid>
                ) : null}

                {consumer.gender ? (
                  <MuiGrid item lg={4} md={6} xs={12}>
                    <MuiTypography>Sex at birth</MuiTypography>
                    <MuiTypography data-testid="sex-at-birth">{consumer.gender}</MuiTypography>
                  </MuiGrid>
                ) : null}
              </MuiGrid>
            </MuiBox>
          </MuiBox>
        </MuiCardContent>
      </MuiCard>

      <MuiGrid container spacing={3}>
        {address ? (
          <MuiGrid item lg={6} xs={12}>
            <MyProfileTile
              title="Home Address"
              onClick={() => history.push('/u/manage-account/edit-address')}
              icon="Home"
            >
              <MuiTypography>
                {address.streetAddress}
                {address.streetAddress2.length ? `, ${address.streetAddress2}` : ''}
              </MuiTypography>
              <MuiTypography>
                {address.city} {address.state}, {address.postalCode}
              </MuiTypography>
            </MyProfileTile>
          </MuiGrid>
        ) : null}

        <MuiGrid item lg={6} xs={12}>
          <MyProfileTile
            title="Phone"
            onClick={() => history.push('/u/manage-account/edit-phone')}
            icon="Phone"
          >
            <MuiTypography>{phone && phone.value ? phone.value : 'undefined'}</MuiTypography>
          </MyProfileTile>
        </MuiGrid>

        {email ? (
          <MuiGrid item lg={6} xs={12}>
            <MyProfileTile
              title="Email Address"
              onClick={() => {
                history.push('/u/manage-account/edit-email');
              }}
              icon="Email"
            >
              <MuiTypography>{email.value}</MuiTypography>
            </MyProfileTile>
          </MuiGrid>
        ) : null}

        <MuiGrid item lg={6} xs={12}>
          <MyProfileTile title="Password" href={CHANGE_PASSWORD_LINK} icon="Lock">
            <MuiTypography>********</MuiTypography>
          </MyProfileTile>
        </MuiGrid>

        <MuiGrid item lg={6} xs={12}>
          <MyProfileTile title="Medical Record Number (MRN)" icon="HospitalShield">
            <MuiTypography>{consumer.consumerId}</MuiTypography>
          </MyProfileTile>
        </MuiGrid>

        {showTwoStepToggle ? (
          <MuiGrid item lg={6} xs={12}>
            <MyProfileTile
              title="Two-Step verification"
              icon="MFALock"
              onToggle={handlePingTwoFactorPopup}
              isToggled={consumer.mfaEnabled}
              isProcessingInOtherTab={isProcessingInOtherTab}
            />
          </MuiGrid>
        ) : null}
      </MuiGrid>
    </MuiBox>
  );
};

const mapState = (state: RootState) => ({
  consumer: profileConsumerSelector(state),
  consumerAvatar: consumerAvatarSelector(state),
  // TODO - Put results from phone and email in here
  verificationStatus: state?.profile?.validation?.status
});

const mapDispatch = (dispatch: RootDispatch) => ({
  sendPin: (sendType: 'phone' | 'email', value: string) => dispatch(sendIDWPin(sendType, value)),
  updateConsumerMfaFlag: (mfaEnabled: boolean) => dispatch(updateConsumerMfaEnabledFlag(mfaEnabled))
});

export default connect(mapState, mapDispatch)(MyProfile);
