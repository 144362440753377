import React from 'react';
import Accordion from 'components/UI/Accordion';
import { MuiBox, MuiTypography } from 'theme/material-ui';
import { Color } from 'modules/styles/colors';
import { treatedConditions } from 'lib/evisit/constants';

interface ConditionDefinitionsProps {
  onExpandAccordion: (accordionSelected: string) => void;
}

interface AccordionMultipleParagraphsProps extends ConditionDefinitionsProps {
  title: string;
  paragraphs: string[];
}

const AccordionMultipleParagraphs = ({
  title,
  paragraphs,
  onExpandAccordion
}: AccordionMultipleParagraphsProps) => {
  return (
    <Accordion
      key={title}
      title={title}
      titleBackgroundColor={Color.grayLight3}
      contentBackgroundColor={Color.grayLight3}
      square
      onOpen={() => onExpandAccordion(title)}
      shadow="none"
    >
      {paragraphs.map(paragraph => (
        <MuiTypography paragraph>{paragraph}</MuiTypography>
      ))}
    </Accordion>
  );
};

export const ConditionDefinitions = ({ onExpandAccordion }: ConditionDefinitionsProps) => (
  <MuiBox data-testid="condition-definitions-content">
    {treatedConditions.slice(0, 5).map(({ condition, definition }) => (
      <AccordionMultipleParagraphs
        title={condition}
        paragraphs={definition}
        onExpandAccordion={onExpandAccordion}
      />
    ))}
    {treatedConditions.slice(5).map(({ condition, definition }) => (
      <AccordionMultipleParagraphs
        title={condition}
        paragraphs={definition}
        onExpandAccordion={onExpandAccordion}
      />
    ))}
  </MuiBox>
);
