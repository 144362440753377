import * as H from 'history';
import { RootDispatch } from 'store/types';
import { PathType } from 'store/pageActions/types';
import { navigationWebStateChange } from 'store/navigation/actions.web';
import analyticsService, { CDP_ID_KEY } from 'services/AnalyticsService.web';
import Config from 'react-native-config';
import { getEvent, isEventExpired } from 'store/events/actions.common';
import { AnyAction } from '@reduxjs/toolkit';
import { AuthResult } from 'store/authentication/reducers';
import { authenticateUserFail } from 'store/authentication/actions';
import { LOCAL_STORAGE_TOKEN } from 'store/authentication/configs.common';
import { Action } from 'modules/types/common';

/**
 * Method: handleUnauthenticated
 * Description: this handles resolving an unauthentication path by the pathType
 */
export async function handleUnauthenticated(
  history: H.History,
  dispatch: RootDispatch,
  pathType: PathType,
  pathname: string
) {
  dispatch(navigationWebStateChange('unauthenticated'));
  localStorage.removeItem(CDP_ID_KEY);
  if (pathType === 'AUTHENTICATED') {
    // Go to login page
    localStorage.setItem('pathAfterLogin', pathname); // set path after login
    history.push('/landing');
  } else if (pathType === 'AUTH_LITE' && Config.ALLOW_AUTH_LIGHT === 'enabled') {
    try {
      const pathArr = pathname?.split('/');
      // Note: pathType === 'AUTH_LITE' should always have events in path
      const idx = pathArr.findIndex(val => val === 'events');

      const eventId = pathArr[idx + 1];
      if (!eventId) {
        throw new Error('No Event Id');
      } else {
        const payloadData = await dispatch(isEventExpired(eventId)).then(
          (response: AnyAction) => response?.payload?.data
        );
        localStorage.removeItem('pathAfterLogin');
        if (payloadData?.expired === false) {
          history.push(pathname);
        } else if (payloadData?.expired) {
          history.push('/al/link-expired');
        }
      }
    } catch (err) {
      // Invalid event, navigate to dashboard.
      history.push('/');
    }
  } else if (pathType === 'LOADING') {
    // Handle user typed in loading location
    localStorage.removeItem('pathAfterLogin');
    history.push('/landing');
  } else {
    localStorage.removeItem('pathAfterLogin');
    // handle unauthenticated location
    history.push(pathname);
  }
}

/**
 * Method: handleAuthentication
 * Description: Handles the authentication callbacks whether there is anything to handle or not.
 */
export async function handleAuthentication(
  pathname: string,
  dispatch: RootDispatch,
  dfdAuth: AuthResult | null
) {
  try {
    // Handle authentication verification
    if (!!dfdAuth?.accessToken) {
      if (pathname.includes('ping')) {
        localStorage.setItem(LOCAL_STORAGE_TOKEN, dfdAuth.accessToken);
      }
    }
  } catch (err) {
    dispatch(authenticateUserFail());
  }
}

/**
 * Method: handleAuthenticated
 * Description: This handles the resolution of an authenticated user by PathType
 */
export async function handleAuthenticatedComplete(
  dispatch: RootDispatch,
  history: H.History,
  pathType: PathType,
  pathname?: string
) {
  const cdpId = localStorage.getItem(CDP_ID_KEY);

  dispatch(navigationWebStateChange('authenticated'));

  if (!!cdpId) {
    localStorage.removeItem(CDP_ID_KEY);
    analyticsService.setUserAttribute(CDP_ID_KEY, cdpId);
  }

  // Note: Auth Light - Authenticated flow. Case: If a user clicks the link and has a MHP account.
  if (pathType === 'AUTH_LITE' && Config.ALLOW_AUTH_LIGHT === 'enabled') {
    try {
      const pathArr = pathname?.split('/');
      const eventId = pathArr?.length && pathArr.slice(-1)[0];
      if (!eventId) {
        throw new Error('No Event Id');
      } else {
        // Get
        const event = await dispatch(getEvent(eventId)).then(
          (response: Action) => response?.payload?.data
        );
        if (!event?.sourceId) {
          throw new Error('Invalid Event');
        }

        if (event.sourceResource === 'labResult') {
          // Note: navigate to labResult screen
          history.push(`/u/health-record/test-results/${event.sourceId}`);
        } else {
          history.push('/');
        }
      }
    } catch (err) {
      // Invalid event, navigate to dashboard.
      history.push('/');
    }
  } else if (pathType !== 'LOADING') {
    // handle original Route
    history.push(pathname || '');
  } else {
    const storedPath = localStorage.getItem('pathAfterLogin');
    if (storedPath) {
      // Remove pathAfterLogin so we don't go there after a logout
      localStorage.removeItem('pathAfterLogin');
      history.push(storedPath);
    } else {
      history.push('/');
    }
  }
}
