import { tableRegex, tableTagsRegex } from 'lib/messaging/rtf/constants';
import {
  linkRegex,
  parseLineIntoTextPartsObject,
  splitTextIntoLinesArray
} from 'lib/messaging/rtf/parseHyperlink';
import React from 'react';
import { MessageDetail } from 'store/messaging/types';
import { MuiTypography } from 'theme/material-ui';
import { generateLinkComponent } from './linkComponent';
import Table from './rtfTable';

export const generateMessageTextComponents = (text: string, plainText: string, isRtf: boolean) => {
  const lines = splitTextIntoLinesArray(text, plainText, isRtf);
  try {
    return lines?.map((line: string) => {
      if (tableRegex.test(line)) {
        return <Table line={line} />;
      }
      if (tableTagsRegex.test(line)) {
        line = line.replace(tableTagsRegex, '');
        return <MuiTypography key={Math.random()}>{line}</MuiTypography>;
      }
      if (linkRegex.test(line)) {
        const lineParts = parseLineIntoTextPartsObject(line);
        const components = lineParts?.map(({ link, before, after }) => {
          return generateLinkComponent(link, before, after);
        });
        return components;
      }
      return <MuiTypography key={Math.random()}>{line}</MuiTypography>;
    });
  } catch (error) {
    return <MuiTypography>plainText</MuiTypography>;
  }
};

// Try using plaintext -> fallback to text -> fallback to No Content
export const getMessageText = (message: MessageDetail, shouldUseRTFConversion: boolean) => {
  try {
    if (shouldUseRTFConversion) {
      if (message?.texts?.length) {
        const messageText: string[] = message.texts.map(item => {
          const isRtf = item.formatType === 'RTF' && !!item.text;
          const text = isRtf ? item.text : item.plainText || item.text || '(No Content)';
          return generateMessageTextComponents(text, item.plainText, isRtf);
        });
        return messageText;
      }
      return <MuiTypography>(No Content)</MuiTypography>;
    }
  } catch (error) {
    return <MuiTypography>(No Content)</MuiTypography>;
  }
  try {
    if (message?.texts?.length) {
      const messageText: string[] = message.texts.map(
        item => item.plainText || item.text || '(No Content)'
      );
      return messageText;
    }
    return '(No Content)';
  } catch (error) {
    return '(No Content)';
  }
};

export default getMessageText;
