import React from 'react';
import Config from 'react-native-config';

import Glossary from 'screens/Glossary/Glossary';
import { languagesValues } from 'lib/constants/translations/components/LanguageSwitcher';
import GranteeList from './GranteeList';
import GrantorList from './GrantorsList';
import MyProfile from './MyProfile/MyProfile';
import HipaaDocuments from './HipaaDocuments';
import TermsOfUse from './TermsOfUse/TermsOfUse';
import MyInsurance from './MyInsurance/MyInsurance';
import MyProviders from './MyProviders/MyProviders';
import AccessLogList from './AuditLog/AuditLogDrawer';
import NotificationsPreferences from '../Notifications/NotificationsPreferences';
import PrivacyNotices from './PrivacyNotices/PrivacyNotices';
import LanguageSwitcher from './LanguageSwitcher';
import MyProfileValidation from './MyProfile/MyProfileValidation';

const AVATAR_FEAT_ENABLED = Config.AVATAR === 'enabled';
const LANGUAGE_SWITCHER_ENABLED = Config.PRIMARY_LANGUAGE_SWITCHER === 'enabled';

export const accountManagementMenuItems = [
  {
    key: 'MyAccount',
    title: 'Account Profile',
    content: () => <MyProfile />,
    validationContent: () => <MyProfileValidation />
  },
  {
    key: 'MyInsurance',
    title: 'My Insurance',
    content: () => <MyInsurance />
  },
  {
    key: 'UserProviders',
    title: 'My Doctors',
    content: () => <MyProviders />
  }
];

export const webAccountManagementMenuItems = [
  {
    key: 'MyInsurance',
    title: 'My Insurance',
    content: () => <MyInsurance />
  },
  {
    key: 'UserProviders',
    title: 'My Doctors',
    content: () => <MyProviders />
  }
];

export const getSettingsItems = (
  allProxiesNeedsRefresh: boolean,
  grantorsListIsEmpty: boolean,
  language: string
) =>
  [
    {
      key: 'Notifications',
      title: 'Notifications',
      content: () => <NotificationsPreferences />
    },
    LANGUAGE_SWITCHER_ENABLED && {
      key: 'LanguageSwitcher',
      title: `Language: ${languagesValues[language]}`,
      content: () => <LanguageSwitcher />
    },
    AVATAR_FEAT_ENABLED &&
      !allProxiesNeedsRefresh &&
      grantorsListIsEmpty && {
        key: 'AvatarManagement',
        title: 'Manage Avatars',
        content: () => null
      }
  ].filter(Boolean);

export const accountSharingMenuItems = [
  {
    key: 'Grantors',
    title: 'People I have access to',
    content: () => <GrantorList />
  },
  {
    key: 'Grantees',
    title: 'People that have access to me',
    content: () => <GranteeList />
  }
];

export const securityAndLegalMenuItems = [
  {
    key: 'TermsAndConditions',
    title: 'Terms of Use',
    content: () => <TermsOfUse />
  },
  {
    key: 'PrivacyNotices',
    title: 'Privacy Notices',
    content: () => <PrivacyNotices />
  },
  {
    key: 'HipaaDocuments',
    title: 'HIPAA Documents',
    content: () => <HipaaDocuments />
  },
  {
    key: 'BillingTermsGlossary',
    title: 'Glossary of Billing Terms',
    content: () => <Glossary />
  },
  {
    key: 'AuditLog',
    title: 'Audit Logs',
    content: () => <AccessLogList />
  }
];
