import { Consumer, Address } from 'store/profile/types';

export function getUpdatedAddressConsumer(consumer: Consumer, address: Address) {
  if (address && !address.country) {
    address.country = 'US';
  }

  consumer.addresses = [address];
  return consumer;
}
