import { Modal } from 'components/CustomModal';
import Svg from 'components/UI/Svg/Svg';
import { MuiTypography } from 'theme/material-ui';
import useNotificationsOptOutTrigger from 'lib/hooks/useNotificationsOptOutTrigger';
import { Color } from 'modules/styles/colors';
import { FontSize, FontWeight } from 'modules/styles/variables';
import React, { useEffect } from 'react';
import bellIcon from 'assets/svgs/icon-bell.svg';
import Spacer from 'components/UI/Layout/Spacer';
import { useHistory } from 'react-router';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

const LineItem = ({ text }: { text: string }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div>
        <Svg name="Check" set="material" size="30" color={Color.primary} />
      </div>
      <div style={{ paddingLeft: '5px' }}>{text}</div>
    </div>
  );
};
export const useNotificationsOptOutDisplay = () => {
  const history = useHistory();
  const hasTriggered = useNotificationsOptOutTrigger();

  useEffect(() => {
    if (hasTriggered) {
      const buttons = {
        styles: {
          direction: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          width: '100%'
        },
        items: [
          {
            styleType: 'mui',
            label: 'Get notifications',
            onClick: () => {
              analyticsService.logEvent(AnalyticsEvent.GetNotificationsClicked);
              history.push('/u/manage-account/notifications');
            },
            styles: { flexGrow: 1 },
            variant: 'contained',
            fullWidth: true
          },
          {
            styleType: 'mui',
            label: 'No notifications',
            onClick: () => {
              analyticsService.logEvent(AnalyticsEvent.NoNotificationsClicked);
            },
            styles: { flexGrow: 1 },
            variant: 'outlined',
            fullWidth: true
          }
        ]
      };
      Modal.show({
        title: (
          <>
            <MuiTypography
              color={Color.primary}
              fontWeight={FontWeight.bold}
              fontSize={FontSize.title}
            >
              Get Notified
            </MuiTypography>
            <Spacer size="small" />
            <div>
              <img src={bellIcon} alt="Bell Notification icon" />
            </div>
          </>
        ),
        description: (
          <div style={{ padding: '0px 20px' }}>
            <MuiTypography fontSize={FontSize.heading} fontWeight={FontWeight.regularbold}>
              Get timely messages and updates about your healthcare activities.
            </MuiTypography>
            <Spacer size="small" />
            <MuiTypography fontSize={FontSize.base} fontWeight={FontWeight.regularbold}>
              Notifications include
            </MuiTypography>
            <LineItem text="Provider message" />
            <LineItem text="Appointments" />
            <LineItem text="Visit Summaries" />
            <LineItem text="Test results, ect." />
          </div>
        ),
        buttons,
        backdrop: { skipBackdropClose: true },
        options: { maxWidth: 'xs', closeIcon: false }
      });
    }
  }, [hasTriggered]);
};

export default useNotificationsOptOutDisplay;
