import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MuiBox, MuiContainer, MuiTypography, MuiTextField } from 'theme/material-ui';
import { Alert } from 'components/Alert';
import SpinnerModal from 'components/UI/Spinner/SpinnerModal';
import ValidationDialog from 'screens/Profile/Validation';
import { Formik } from 'formik';
import { FontSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { isValidEmail } from 'modules/utils/EmailUtils';
import { SendPinProps, ValidateResultsState } from 'store/profile/types';
import { ProfileUpdateMessages } from 'store/profile/constants';
import {
  profileValidationIsFetchingSelector,
  sendOTPIsFetchingSelector
} from 'store/profile/selectors';
import StyledButton from 'components/UI/Button/StyledButton';
import { StyledEditContactDiv, ContactCancelButton, ContactHeader } from '../styled';

interface FormikValues {
  email: string;
}

export interface EditEmailScreenProps {
  resetValidation?: () => void;
  sendError?: boolean;
  validated?: ValidateResultsState;
  onSubmit: (pin: string, emailToSubmit: string) => Promise<any>;
  sendPin: (data: SendPinProps) => Promise<any>;
  handleCancel: () => void;
  isLoading: boolean;
  initialEmail: string;
}

export default function EditEmailComponent({
  resetValidation,
  sendError,
  validated,
  onSubmit,
  sendPin,
  handleCancel,
  isLoading,
  initialEmail
}: EditEmailScreenProps) {
  const isFetching = useSelector(profileValidationIsFetchingSelector);
  const otpIsFetching = useSelector(sendOTPIsFetchingSelector);

  const [showValidation, setShowValidation] = useState(false);
  const [submissionValues, setSubmissionValues] = useState<string>(initialEmail);
  const initialValues: FormikValues = {
    email: initialEmail
  };

  const onValidate = (values: FormikValues) => {
    return !isValidEmail(values.email) ? { email: 'Please enter a valid email' } : {};
  };

  const resetAndClose = () => {
    if (resetValidation) {
      resetValidation();
    }
    setShowValidation(false);
  };

  const showErrorAlert = () => {
    Alert.alert(
      'There was an issue sending your pin',
      ProfileUpdateMessages.VALIDATE_EMAIL.message,
      [
        {
          text: 'OK',
          onPress: () => resetAndClose
        }
      ]
    );
  };

  const validateAndSubmit = async (values: FormikValues) => {
    const { email } = values;
    if (email && email !== initialValues.email) {
      setSubmissionValues(email);
      const res = await sendPin({ type: 'email', email });
      if (!res.error && res?.payload?.data?.success !== false) {
        setShowValidation(true);
      } else {
        showErrorAlert();
      }
    }
  };

  return (
    <MuiBox mt={6}>
      <SpinnerModal isLoading={isLoading || isFetching || otpIsFetching} />
      <MuiContainer maxWidth="sm">
        {showValidation && (
          <ValidationDialog
            resetAndClose={resetAndClose}
            sendError={!!sendError}
            validated={!!(validated?.results && validated?.results[submissionValues]?.verifyCode)}
            validateAction={(pin: string) => {
              if (submissionValues) {
                return onSubmit(pin, submissionValues);
              }
              return Promise.resolve();
            }}
          >
            <MuiBox textAlign="center" mb={3}>
              <MuiTypography gutterBottom>
                A code has been emailed to the email you provided
              </MuiTypography>
              <MuiTypography>Please enter the code below</MuiTypography>
            </MuiBox>
          </ValidationDialog>
        )}
        <Formik initialValues={initialValues} validate={onValidate} onSubmit={validateAndSubmit}>
          {({ touched, errors, values, handleChange, handleBlur, isValid, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <StyledEditContactDiv p={3}>
                  <ContactHeader mb={6}>
                    <MuiTypography
                      fontWeight={600}
                      fontSize={FontSize.large}
                      color={Color.textLight}
                    >
                      Edit Email
                    </MuiTypography>
                  </ContactHeader>

                  <MuiBox mb={3}>
                    <MuiTextField
                      type="email"
                      name="email"
                      fullWidth
                      placeholder="Enter Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email ? errors.email : null}
                    />
                  </MuiBox>

                  <MuiBox p={3}>
                    <MuiBox mb={1}>
                      <ContactCancelButton fullWidth onClick={handleCancel}>
                        Cancel
                      </ContactCancelButton>
                    </MuiBox>

                    <StyledButton
                      fullWidth
                      disabled={!isValid || values.email === initialValues.email}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </StyledButton>
                  </MuiBox>
                </StyledEditContactDiv>
              </form>
            );
          }}
        </Formik>
      </MuiContainer>
    </MuiBox>
  );
}
