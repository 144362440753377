import React from 'react';
import { MuiButton, MuiTypography } from 'theme/material-ui';
import myStrengthThumb from 'assets/HealthTools/myStrength-thumb.png';
import { HealthToolCardData } from 'components/HealthToolsCards/HealthToolCard';
import { MentalHealthType } from 'services/AnalyticsService.common';
import Spacer from 'components/UI/Layout/Spacer';
import { FontSize } from 'shared/src/modules/styles/variables';

const ListItem = ({ children }: { children: JSX.Element | string }) => (
  <li style={{ display: 'list-item', listStyleType: 'disc', listStylePosition: 'inside' }}>
    {children}
  </li>
);

export function getMentalHealthProps(
  mentalHealthVariant: MentalHealthType
): {
  text: JSX.Element | string;
  url: string;
} {
  let text: JSX.Element | string = '';
  let url =
    'https://intermountainhealth.crediblemind.com/?utm_source=ut-portal&utm_medium=Int-promo&utm_campaign=portal-primary&utm_content=';
  switch (mentalHealthVariant) {
    case 'd':
      url += 't1v4';
      text = (
        <div>
          <div style={{ textAlign: 'center', fontSize: FontSize.xsmall, fontWeight: 'bold' }}>
            Take charge of your mental health with resources to help you:
          </div>
          <Spacer size="small" />
          <div style={{ fontSize: FontSize.xsmall, fontWeight: 'bold' }}>
            <div style={{ width: '49%', display: 'inline-block' }}>
              <ul>
                <ListItem>Relieve stress</ListItem>
                <ListItem>Manage anxiety</ListItem>
                <ListItem>Sleep better</ListItem>
              </ul>
            </div>
            <div style={{ width: '49%', display: 'inline-block' }}>
              <ul style={{ listStyleType: '&#9679;' }}>
                <ListItem>Beat burnout</ListItem>
                <ListItem>Prevent depression</ListItem>
                <ListItem>And so much more…</ListItem>
              </ul>
            </div>
          </div>
          <Spacer size="small" />
          <div style={{ textAlign: 'center' }}>
            <MuiButton color="primary" variant="contained" fullWidth>
              Get Started!
            </MuiButton>
          </div>
        </div>
      ) as JSX.Element;
      break;
    case 'c':
      url += 't1v3';
      text = 'We’re partnering with CredibleMind to bring you mental health resources';
      break;
    case 'b':
      url += 't1v2';
      text = 'Explore mental health resources across 100+ topics to help you live a healthier life';
      break;
    default:
    case 'a':
      url += 't1v1';
      text =
        'Mental health resources for everything from managing anxiety to sleeping better and so much more…';
  }
  return { url, text };
}

export const myStrengthCard = (mentalHealthVariant: MentalHealthType): HealthToolCardData => {
  const { text, url } = getMentalHealthProps(mentalHealthVariant);

  return {
    healthToolCardKey: 'myStrength',
    description: <MuiTypography display="inline">{text}</MuiTypography>,
    path: '/u/health-tools/mystrength',
    thumb: myStrengthThumb,
    url,
    variant: mentalHealthVariant
  };
};
