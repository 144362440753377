import React, { useEffect, useState } from 'react';

import { Color } from 'modules/styles/colors';
import { EventEmitter } from 'services/EventEmitter';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import Svg from 'components/UI/Svg/Svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useMediaQuery } from '@material-ui/core';
import { MuiBox, MuiButton, MuiDialog, MuiDialogContent, useTheme } from '../../theme/material-ui';
import { ConnectCareButton } from '../ConnectCare/ConnectCareButton';
import FlexBox from '../UI/Layout/FlexBox';
import { Typography } from '../UI/Typography/styled';
import { FontSize, IconSize } from 'modules/styles/variables';
import { Spacing } from 'modules/styles/variables.web';
import SvgButton from '../UI/Svg/SvgButton';
import { convertToLowerKabobCase } from 'modules/utils/StringUtils';

export type ModalButtonsStyles = {
  direction?: string;
  flexWrap?: string;
  justifyContent?: string;
  alignItems?: string;
};

export type ModalButton = {
  styles?: any;
  styleType?: 'mui' | 'connectCare';
  variant?: 'outlined' | 'contained';
  fullWidth?: boolean;
  label?: string;
  icon?: string;
  onClick?: () => void;
  skipOnClose?: boolean;
};

export type ModalButtons = {
  items?: (ModalButton | null)[];
  styles?: ModalButtonsStyles;
};

export type ModalBackdrop = {
  skipBackdropClose?: boolean;
  handleBackdropClose?: () => void;
};

export type ModalOptions = {
  skipCancelable?: boolean;
  closeIcon?: boolean;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullScreen?: boolean;
  animationType?: 'fade' | 'slide' | 'none';
  onClose?: () => void;
};

export type ModalProps = {
  severity?: 'error' | 'warning' | 'info' | 'success';
  customIcon?: JSX.Element;
  backdrop?: ModalBackdrop;
  title: React.ReactNode;
  description?: React.ReactNode;
  options?: ModalOptions;
  footNote?: React.ReactNode;
  buttons?: ModalButtons;
};

type ColorKey = keyof typeof Color;

const confirmBus = new EventEmitter();

type ShowParams = {
  severity?: ModalProps['severity'];
  customIcon?: ModalProps['customIcon'];
  backdrop?: ModalProps['backdrop'];
  title?: ModalProps['title'];
  description?: ModalProps['description'];
  buttons: ModalProps['buttons'];
  options?: ModalProps['options'];
  footNote?: ModalProps['footNote'];
};

export class Modal {
  static show(params: ShowParams) {
    confirmBus.emit('show', params);
  }
  static hide() {
    confirmBus.emit('hide');
  }
}

function SeverityIcon({ severity }: { severity: ModalProps['severity'] }) {
  const baseProps = (opts = {}, styleOpts = {}) => ({
    accessibilityLabel: severity,
    ...opts,
    style: {
      fontSize: FontSize.xlarge,
      color: Color[severity as ColorKey],
      ...styleOpts
    }
  });

  let props = baseProps();

  switch (severity) {
    case 'warning':
      props = baseProps({}, { color: Color.error });
      return <WarningIcon {...props} />;
    case 'error':
      return <ErrorIcon {...props} />;
    case 'info':
      props = baseProps({}, { color: Color.primary });
      return <Svg set="assets" name="InfoIcon" size={IconSize.xLarge} color={Color.primary} />;
    case 'success':
      return <CheckCircleIcon {...props} />;
    default:
      return null;
  }
}

function CustomModal(): JSX.Element | null {
  const [props, setProps] = useState<ModalProps | null>(null);

  useEffect(() => {
    const unsubscribe = confirmBus.on(
      'show',
      ({
        severity,
        customIcon,
        backdrop,
        title,
        description,
        buttons,
        options,
        footNote
      }: ModalProps) => {
        if (props !== null) {
          return;
        }

        setProps(
          prevState =>
            prevState ||
            ({
              severity,
              customIcon,
              backdrop,
              title,
              description,
              buttons,
              options,
              footNote
            } as ModalProps)
        );
      }
    );
    const hideListener = confirmBus.on('hide', () => {
      setProps(null);
    });

    return () => {
      unsubscribe();
      hideListener();
    };
  }, [setProps, confirmBus]);

  const wrapOnClick = (
    onClickHandler: ModalButton['onClick'],
    skipOnClose: ModalButton['skipOnClose']
  ) => () => {
    onClickHandler?.();
    if (!skipOnClose) {
      setProps(null);
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!props) {
    return null;
  }

  const {
    severity,
    customIcon,
    backdrop,
    title,
    description,
    options: optionsProp,
    footNote,
    buttons
  } = props;

  const options: ModalOptions = {
    fullWidth: true,
    maxWidth: 'sm',
    fullScreen: isSmallScreen,
    closeIcon: true,
    ...optionsProp
  };

  const renderButtons = buttons?.items?.map((btn: ModalButton | null): JSX.Element | null => {
    const { styleType = 'connectCare' } = btn ?? {};
    return btn ? (
      <MuiBox marginX={Spacing.xSmall} marginY={1} flexGrow={btn?.styles?.flexGrow ?? 1}>
        {styleType === 'connectCare' ? (
          <ConnectCareButton
            data-testid={convertToLowerKabobCase(`${btn?.label}-button`)}
            size="large"
            onClick={wrapOnClick(btn?.onClick, btn?.skipOnClose)}
            {...btn?.styles}
          >
            {btn?.label}
          </ConnectCareButton>
        ) : (
          <MuiButton
            onClick={wrapOnClick(btn?.onClick, btn?.skipOnClose)}
            color="primary"
            variant={btn?.variant || 'outlined'}
            fullWidth={btn?.fullWidth}
            style={btn?.styles}
          >
            {btn?.label}
          </MuiButton>
        )}
      </MuiBox>
    ) : null;
  });

  return (
    <MuiDialog
      open
      maxWidth={options?.maxWidth}
      fullWidth={options?.fullWidth}
      fullScreen={options?.fullScreen}
      onBackdropClick={wrapOnClick(backdrop?.handleBackdropClose, backdrop?.skipBackdropClose)}
      data-testid="modal"
    >
      <MuiBox bgcolor={Color.primary} height="3px" width="100%"></MuiBox>
      {options?.closeIcon ? (
        <FlexBox alignItems="flex-end">
          <SvgButton
            color={Color.black}
            appearance="transparent"
            set="material"
            name="close"
            accessibilityLabel="Close Modal"
            data-testid="close-button"
            onClick={wrapOnClick(options?.onClose, options?.skipCancelable)}
          />
        </FlexBox>
      ) : null}
      <MuiDialogContent>
        <FlexBox
          alignItems="center"
          hSpacing={Spacing.xSmall}
          vSpacingBottom={20}
          vSpacingTop={options?.closeIcon ? 0 : 20}
        >
          {severity ? <SeverityIcon severity={severity} /> : null}
          {customIcon}
          {typeof title === 'string' ? (
            <MuiBox marginTop={Spacing.xSmall}>
              <Typography tag="h3" fontSize={FontSize.large} textAlign="center" color="#777">
                {title}
              </Typography>
            </MuiBox>
          ) : (
            title
          )}
          {typeof description === 'string' ? (
            <MuiBox
              marginTop={Spacing.xSmall}
              marginBottom={Spacing.xSmall}
              data-testid="description"
              color="#777"
            >
              <Typography textAlign="center">{description}</Typography>
            </MuiBox>
          ) : (
            description
          )}
          <FlexBox
            flexDirection={buttons?.styles?.direction ?? 'column'}
            flexWrap={buttons?.styles?.flexWrap}
            justifyContent={buttons?.styles?.justifyContent}
            width={buttons?.styles?.width}
          >
            <MuiBox marginTop={Spacing.xSmall} />
            {renderButtons}
          </FlexBox>
          {typeof footNote === 'string' ? (
            <MuiBox
              flexDirection="row"
              alignItems="start"
              justifyContent="space-around"
              marginTop={Spacing.medium}
            >
              <Typography textAlign="left" fontSize={FontSize.small}>
                {footNote}
              </Typography>
            </MuiBox>
          ) : (
            footNote
          )}
        </FlexBox>
      </MuiDialogContent>
    </MuiDialog>
  );
}

export default CustomModal;
