import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MuiBox, MuiContainer, MuiTypography } from 'theme/material-ui';
import { StyledEditContactDiv, ContactCancelButton, ContactHeader } from '../styled';
import SpinnerModal from 'components/UI/Spinner/SpinnerModal';
import { Alert } from 'components/Alert';
import ValidationDialog from 'screens/Profile/Validation';
import { Formik } from 'formik';
import { FontSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { isUSPhoneNumber, cleanPhone, phoneNumberMask } from 'modules/utils/PhoneUtils';
import { ProfileUpdateMessages } from 'store/profile/constants';
import {
  profileValidationIsFetchingSelector,
  sendOTPIsFetchingSelector
} from 'store/profile/selectors';
import { SendPinProps, ValidateResultsState } from 'store/profile/types';
import StyledButton from 'components/UI/Button/StyledButton';
import { PhoneNumberForm } from './styled';

interface FormikValues {
  phone: string;
}

interface EditPhoneComponentProps {
  initialMobile: string;
  handleCancel: () => void;
  handleSubmit: (pin: string, numberToSubmit: string) => Promise<any>;
  sendPin: (data: SendPinProps) => Promise<any>;
  isLoading: boolean;
  resetValidation?: () => void;
  validated?: ValidateResultsState;
  sendError?: boolean;
}

export default function EditPhoneComponent({
  initialMobile,
  handleCancel,
  handleSubmit,
  isLoading,
  resetValidation,
  validated,
  sendError,
  sendPin
}: EditPhoneComponentProps) {
  const isFetching = useSelector(profileValidationIsFetchingSelector);
  const otpIsFetching = useSelector(sendOTPIsFetchingSelector);
  const [showValidation, setShowValidation] = useState(false);
  const [submissionValues, setSubmissionValues] = useState<string>(initialMobile);

  const initialValues: FormikValues = {
    phone: initialMobile
  };

  const handleValidate = (values: FormikValues) => {
    return !isUSPhoneNumber(values.phone) ? { phone: 'Please enter a valid phone number' } : {};
  };

  const resetAndClose = () => {
    if (resetValidation) {
      resetValidation();
    }
    setShowValidation(false);
  };

  const showErrorAlert = () => {
    Alert.alert(
      'There was an issue sending your pin.',
      ProfileUpdateMessages.VALIDATE_PHONE.message,
      [
        {
          text: 'OK',
          onPress: () => resetAndClose
        }
      ]
    );
  };

  const validateAndSubmit = async (values: FormikValues) => {
    const numberToSubmit = cleanPhone(String(values.phone));
    if (numberToSubmit !== initialMobile) {
      setSubmissionValues(numberToSubmit);
      const res = await sendPin({ type: 'sms', phone: numberToSubmit });
      if (!res.error && res?.payload?.data?.success !== false) {
        setShowValidation(true);
      } else {
        showErrorAlert();
      }
    }
  };

  return (
    <MuiBox mt={6}>
      <SpinnerModal isLoading={isLoading || isFetching || otpIsFetching} />
      <MuiContainer maxWidth="sm">
        {showValidation && (
          <ValidationDialog
            resetAndClose={resetAndClose}
            sendError={!!sendError}
            validated={!!(validated?.results && validated?.results[submissionValues]?.verifyCode)}
            validateAction={pin => {
              if (submissionValues) {
                return handleSubmit(pin, submissionValues);
              }
              return Promise.resolve();
            }}
          >
            <MuiBox textAlign="center" mb={3}>
              <MuiTypography gutterBottom>
                A code has been sent to the number you provided
              </MuiTypography>
              <MuiTypography>Please enter the code below</MuiTypography>
            </MuiBox>
          </ValidationDialog>
        )}
        <Formik
          initialValues={initialValues}
          validate={handleValidate}
          onSubmit={validateAndSubmit}
        >
          {({ touched, errors, values, handleChange, handleBlur, isValid, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <StyledEditContactDiv p={3}>
                <ContactHeader mb={6}>
                  <MuiTypography fontWeight={600} fontSize={FontSize.large} color={Color.textLight}>
                    Edit Phone
                  </MuiTypography>
                </ContactHeader>

                <ContactHeader mb={3}>
                  <PhoneNumberForm
                    mask={phoneNumberMask}
                    type="phone"
                    name="phone"
                    fullWidth
                    placeholder="Enter Phone Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone ? errors.phone : null}
                  />
                </ContactHeader>

                <MuiBox p={3}>
                  <MuiBox mb={1}>
                    <ContactCancelButton fullWidth onClick={handleCancel}>
                      Cancel
                    </ContactCancelButton>
                  </MuiBox>

                  <StyledButton
                    fullWidth
                    disabled={!isValid || values.phone === initialValues.phone}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </StyledButton>
                </MuiBox>
              </StyledEditContactDiv>
            </form>
          )}
        </Formik>
      </MuiContainer>
    </MuiBox>
  );
}
